/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { getColor, Theme } from 'styles/theme';
import {
  primaryBrandButtonLarge,
  secondaryBrandButtonLarge,
  tertiaryBrandButtonLarge,
} from 'components/Globals/Base/Button/buttonStyles';
import { bluePrimaryLink } from 'components/Globals/Base/Link/LinkStyles';
import { ctaCaption } from 'styles/paragraphs';
import { font } from 'styles/globals';

const gridItemStyling = css`
  & > *:not(:empty) {
    min-height: 62px;
    &:not(:last-child) {
      margin-bottom: 24px;
    }
  }
`;

export const head2Head = css<{ theme: Theme }>`
  // Layout styles
  .h2h-widget {
    * {
        font-family: ${font.graphikCond};
        line-height: 150%;
        letter-spacing: 0.5px;
        color: ${getColor('textPrimary')}
    }
    p {
      margin: 0;
      font-size: 16px;
      line-height: 120%;
    }

    background: 
      linear-gradient(to bottom, ${getColor('shadeBrandPrimary100')} 370px, ${getColor('surfaceWhite')} 371px );
    display: grid;
    grid-template-columns: 288px 0 288px;
    grid-template-rows: repeat(7, auto);
    grid-column-gap: 16px;
    padding: 32px;
    max-width: 672px;
    margin: 0 auto;
    overflow-y: auto;
    > .wp-block-group {
        padding: 24px;
        background-color: ${getColor('surfaceWhite')};
        box-shadow: 4px 4px 20px 0px rgba(17, 17, 17, 0.05);
    }
    .h2h-col1 {
        display: grid;
        grid-template-rows: subgrid;
        grid-area: 1 / 1 / 8 / 2;
        > .wp-block-group__inner-container {
            display: grid;
            grid-template-rows: subgrid;
            grid-area: 1 / 1 / 8 / 2;
            ${gridItemStyling}
        }
    }
    .h2h-col2 {
        display: grid;
        grid-template-rows: subgrid;
        grid-area: 1 / 3 / 8 / 4;
        > .wp-block-group__inner-container {
            display: grid;
            grid-template-rows: subgrid;
            grid-area: 1 / 1 / 8 / 2;
            ${gridItemStyling}
        }
    }

    .h2h-colVs {
        padding: 0;
        grid-area: 1 / 2 / 8 / 3;
        .wp-block-group {
            background: url('/icons/h2hVsIcon.svg') center/contain ;
            box-shadow: none;
            width: 140px;
            height: 140px;
            padding: 0;
            position: relative;
            z-index: 9;
            margin: 36px -70px 0 -70px;
            > .wp-block-group__inner-container {
                display: none;
            }
        }
    }

    // Element styles
    h3 {
      color: ${getColor('textPrimary')};
      font-family: ${font.graphikCond};
      font-size: 24px;
      font-weight: 600;
    }
    .h2h-title {
      text-align: center;
    }

    // Image + Rates link
    .h2h-image {
      // TODO: FECM-336 - Improve implementation for usage of next/image
      // overwriting next/image behavior
      span {
        span {
          padding: 0!important;
        }
        img {
          position: relative!important;
          min-width: unset!important;
          max-width: unset!important;
          min-height: unset!important;
          max-height: unset!important;
          width: auto!important;
          height: 76px!important;
        }
      }
    }

    // Intro block
    .h2h-intro .wp-block-group__inner-container{
      padding: 8px;
      background-color: ${getColor('shadeBrandPrimary100')};
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    // Terms list
    .terms-list {
      display: flex;
      flex-direction: column;
      gap: 9px;
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        color: ${getColor('textSecondary')};
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        padding-bottom: 8px;
        margin: 0;
        border-bottom: 1px solid ${getColor('borderSecondary')};
        mark {
          color: ${getColor('textAccentPrimary')}
        }

      }
    }

    // Rates list
    .rates-list {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin: 8px 0 0 0;
      padding: 0;
      li {
        list-style: none;
        color: ${getColor('textPrimary')};
        font-size: 16px;
        line-height: 150%;
        display: flex;
        gap: 8px;
        margin: 0;
        mark {
          display: inline-block;
          font-size: 12px;
          font-weight: 400;
          text-align: center;
          line-height: 190%;
          color: ${getColor('textWhite')};
          // !important needed to overite inline style on <mark> element
          background-color: ${getColor('surfaceBrand')} !important; 
          border-radius: 50%;
          min-width: 24px;
          width: 24px;
          height: 24px;
        }
      }
    }

    // Tooltips
    span[role="tooltip"] {
      position: relative;
      display: inline-block;
      width: 24px;
      height: 24px;
      cursor: pointer;
      z-index: 3;
      margin: -6px 0;
      background-image: url('/icons/TooltipIcon.svg');
      background-size: 60%;
      background-position: center;
      background-repeat: no-repeat;

      &:hover, &:focus, &:focus-within {
        z-index: 4;
      }

      & > svg {
        display: none;
      }

      &:focus:after, &:hover:after {
        position: absolute;
        z-index: 3;
        font-family : ${font.graphikCompact};
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        padding: 8px;
        width: 175px;
        background-color: ${getColor('surfaceWhite')};
        box-shadow: 2px 2px 4px 2px rgba(17, 17, 17, 0.05);;
        color: ${getColor('textPrimary')};
        content: attr(data-tooltip);
        bottom: 0;
        transform: translate(0, -24px);
        border-bottom: 4px solid ${getColor('borderAccentPrimary')};
      }
    }
      
    // Links
    .link-rates {
      margin: 8px 0 0 0;
      a {
        font-size: 14px;
        font-weight: 600;
        line-height: 120%;
        ${bluePrimaryLink};
      }
    }

    :not(.no-button) {
      &.link-offer a {
        ${primaryBrandButtonLarge};
      }
      &.link-review a {
        ${secondaryBrandButtonLarge};
      }
    }
    .no-button {
      &.link-offer a, 
      &.link-review a {
        ${tertiaryBrandButtonLarge}
      }
    }

    :not(.no-button), .no-button {
      &.link-offer a, &.link-review a, &.link-rates a  {
        display: flex;
        justify-content: center;
      }
    }
      
    .cta-caption {
      ${ctaCaption};  
    }
  }
`;
