/* eslint-disable import/prefer-default-export */
import { css } from 'styled-components';
import { getColor, Theme } from 'styles/theme';
import { breakpoint, font } from 'styles/globals';

export const keyTakeaways = css<{ theme: Theme }>`
  .key-takeaways-widget {
    padding: 24px;
    background-color: ${getColor('surfacePrimary')};
    border-top: 4px solid ${getColor('borderAccentPrimary')};
      
    .key-takeaways-header {
      display: flex;
      align-items: center;
      gap: 16px;
      margin: 0 0 16px 0;
      font-family: ${font.graphikCond};
      font-weight: 600;
      @media (${breakpoint.mdMax}) {
        font-size: 20px;
      }
      
      &::before {
        content: '';
        background: url('/icons/KeyTakeaways.svg') no-repeat center/contain;
        width: 30px;
        height: 30px;
        @media (${breakpoint.mdMax}) {
          width: 24px;
          height: 24px;
        }
      }
    }
      
    .key-takeaways-content {
      font-family: ${font.graphikCompact};
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0.5px;
      text-align: left;
      p {
        margin: 16px 0;
      }
    }
  }
`;
