/* eslint-disable import/prefer-default-export */

import { Microsites } from 'constants/constants';
import { css } from 'styled-components';
import { primaryBrandButtonSmall } from 'components/Globals/Base/Button/buttonStyles';
import { keyTakeaways } from 'styles/widgets/keyTakeaways';
import { breakpoint, color, font } from './globals';
import { productCardStyles } from './widgets/productCard';
import { comparisonTableStyles } from './widgets/comparisonTable';
import { getColor, Theme } from './theme';
import { proTipBox } from './widgets/proTipBox';
import { monthlyArticle } from './widgets/monthlyArticleBox';
import { head2Head } from './widgets/head2head';
import { prosCons } from './widgets/prosCons';
import { details } from './widgets/details';
import { whyTrustUs } from './widgets/whyTrustUs';
import { readMoreBox } from './widgets/readMoreBox';

// TODO: use the articleContent styling on Article V2 and recommends landing too

export interface ArticleContentProps {
  $subdomain?: string;
  $stickyTop?: number | null;
  $subdomainColor?: string;
  $headerHeight?: number;
  theme: Theme;
}

export const handleScrollJump = (subdomain?: string) => {
  switch (subdomain) {
    case null:
      return '195px';
    case Microsites.Well:
      return '80px';
    case Microsites.Crypto:
      return '130px';
    case Microsites.Recommends:
      return '80px';
    default:
      break;
  }
  return null;
};
export const handleScrollJumpMobile = (subdomain?: string) => {
  switch (subdomain) {
    case null:
      return '115px';
    case Microsites.Well:
      return '55px';
    case Microsites.Crypto:
      return '110px';
    case Microsites.Recommends:
      return '55px';
    default:
      break;
  }
  return null;
};

// TODO: use this common style for all articles content
export const articleContentStyle = css<ArticleContentProps>`

  div[class^="rawHtml-content"] {
    margin-bottom: 24px; //TODO: remove this

    > p:first-of-type {
      margin-top: 0px;
    }
    h1, h2, h3, h4, h5, h6 {
      scroll-margin-top: ${({ $subdomain }) => handleScrollJump($subdomain)};
      @media (${breakpoint.mdMax}) {
        scroll-margin-top: ${({ $subdomain }) => handleScrollJumpMobile($subdomain)};
      }
    }
    iframe {
      aspect-ratio: 16 / 9;
    }

    @media (${breakpoint.mdMin}) {
      .paywall-selector {
        top: ${({ $stickyTop }) => ($stickyTop ? `${$stickyTop + 20}px` : '10%')};
      }
    }
  }

  figure[class*="wp-block-embed-youtube"] {
    div.wp-block-embed__wrapper {
      aspect-ratio: 16 / 9;
      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  @media (${breakpoint.mdMax}) {
    div.wp-block-embed__wrapper {
      iframe {
        width: 100%;
      }
    }

    .embeddable-savings-calculator {
      height: 800px !important; // Moneylion savings calculator required style
    }
  }

  .source-caption {
    border: solid red;
  }

  .twitter-tweet {
    margin: auto !important;
  }

  iframe.instagram-media {
    margin: auto !important;
  }

  .wp-block-embed__wrapper {
    max-width: none;
    width: 100%;

    @media (${breakpoint.mdMin}) {
      margin: 0 auto 24px auto;
      padding: 0;
    }
  }

  figure {
    .wp-block-embed__wrapper {
      margin: 0;
    }
  }

  // remove margin from nativo moap tout
  div[class^="ntv"] {
    margin: 0;
  }

  .related-video-container {
    margin-bottom: 32px;
    h3 {
      font-weight: 600;
    }
    hr {
      margin: 0px 0px 16px;
    }
  }
  
  .wp-block-button {
    position: relative;

    > a::before {
      content: '';
      position: absolute;
      inset: 0;
    }

    a:not(.styled-custom-link) {
      ${primaryBrandButtonSmall}
      // overwrite article content anchor styling
      color: ${getColor('textWhite')}!important;
      text-decoration: none!important;
    }
  }

  .table-container{
    overflow: auto;
  }
  
  ${({ $subdomain }) =>
    Microsites.Recommends === $subdomain &&
    `h5.wp-block-heading {
      font-family: ${font.graphikCompact};
      color: ${color.recommendsDarkGreen};
      font-weight: 600;
      font-size: 16px;
      line-height: 19.2px;
    }
  `}

  ${productCardStyles}
  ${comparisonTableStyles}
  ${proTipBox}
  ${monthlyArticle}
  ${head2Head}
  ${prosCons}
  ${keyTakeaways}
  ${details}
  ${whyTrustUs}
  ${readMoreBox}
`;
