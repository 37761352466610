import CustomLink from 'components/Globals/Base/Link';
import styled, { css } from 'styled-components';
import { bkptVal, breakpoint } from 'styles/globals';
import { getBodyStyle, getColor, Theme } from 'styles/theme';
import Heading from 'styles/typography/Heading';
import { Microsites } from 'constants/constants';

export const bulletListStyling = css<{ theme: Theme }>`  
  ul, ol {
    color: ${getColor('textPrimary')};
    ${getBodyStyle('graphikCond', { default: 'default' })};
    list-style-type: disc;
    padding: 0;
    
    &:not(.card-rates-list) {
      li {
        list-style-type: none;
        display: flex;
        &::before {
          flex-shrink: 0;
          content: "";
          display: inline-block;
          width: 6px;
          height: 6px;
          background-color: ${getColor('neutralLightGray3')};
          border-radius: 50%;
          margin: calc(1rem/2) calc(1rem/2) calc(1rem/2) 0px;
        }
      } 
    }
  }
`;

export const basicTableStyling = css<{ theme: Theme }>`
  table {
    margin-top: 16px;
    width: 100%;
    border-collapse: collapse;

    tr:not(:last-child) {
      border-bottom: 1px solid ${getColor('borderSecondary')};
    }

    td, th {
      padding: 8px 0;
      ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')}
    }

    td {
      color: ${getColor('textSecondary')};
      &:nth-child(even) {
        color: ${getColor('textAccentPrimary')};
        text-align: right;
      }
    }

    th {
      color: ${getColor('textPrimary')};
      text-align: left;
      &:nth-child(even) {
        text-align: right;
      }
    }
  }
`;

export const educationTableStyling = css<{ theme: Theme }>`
  table {
    margin-top: 16px;
    width: 100%;
    border-collapse: collapse;

    tr:not(:last-child) {
      border-bottom: 1px solid ${getColor('borderSecondary')};
    }

    td, th {
      padding: 8px 0;
      ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')}
    }

    td {
      color: ${getColor('textSecondary')};
      &:nth-child(even) {
        color: ${getColor('textAccentPrimary')};
        text-align: right;
      }
    }

    th {
      color: ${getColor('textPrimary')};
      text-align: left;
      &:nth-child(even) {
        text-align: right;
      }
    }

    // Applying a different styling for tables but only for education as confirmedc with UI/UX
    ${({ theme }) =>
    (theme.microsite === Microsites.Education || Microsites.Recommends ?
      css<{ theme: Theme }>`
        td, th {
          color: ${getColor('textPrimary')};
          ${getBodyStyle('graphikCompact', { default: 'default' })}
        }

        td {
          &:nth-child(odd) {
            text-transform: uppercase;
          }
          &:nth-child(even) {
            ${getBodyStyle('graphikCompact', { default: 'default' }, 'semiBold')}
            color: ${getColor('textAccentPrimary')};
          }
        }
      ` :
      '')}
  }
`;

export const CardWrapper = styled.div<{ theme: Theme; $isAdvertisement: boolean }>`

  section > section {
    ${getBodyStyle('graphikCond', { default: 'default' })};
    text-align: left;
  }

  .accordion-wrapper {
    section {
      padding-top: 8px;
    }
  }

  border-top: ${(props) =>
    (!props.$isAdvertisement ? css<{ theme: Theme }>`3px solid ${getColor('borderAccentPrimary')}` : 'none')};
  box-shadow: 0px 4px 20px 0px ${getColor('surfaceBlack')}26;

  & {
    container: cardWrapper / inline-size;

    .tabs-wrapper {
      display: block;
    }

    .accordion-wrapper {
      display: none;
    }

    .button-mobile-wrapper {
      display: none; 
    }

    @container cardWrapper (max-width: ${bkptVal.mm}rem) {
      .content-wrapper {
        grid-template-columns: 1fr 1fr;
        gap: 0;
      }

      .right-column {
        margin-bottom: 16px;
        grid-column: 1 / span 1;
      }

      .content {
        grid-row: 2;
        grid-column: 1 / span 2;

        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }

      .card-title:not(.hasSubtitle) {
        margin-bottom: 16px;
      }

      .card-title a {
        font-size: 24px;
        line-height: 28px;
      }

      .content-wrapper .button-desktop-wrapper {
        display: none;
      }

      .content-wrapper .button-mobile-wrapper {
        display: flex;
        flex-direction: column;
        grid-column: span 2;
        margin-top: 16px;
        a {
          width: 100%;
        }
      }

      .tabs-wrapper {
        display: none;
      }
      
      .accordion-wrapper {
        display: block;
      }
    }
  }

  ${bulletListStyling}

  ${educationTableStyling}
`;

export const ContentWrapper = styled.div`
  display: grid;
  padding: 24px;
  grid-template-columns: 1fr 168px;
  gap: 24px;
`;

export const AdvertisementDisclaimer = styled.div<{ theme: Theme }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${getColor('surfaceBrand')};
  ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};
  color: ${getColor('textWhite')};
  padding: 8px;
`;

export const RightColumn = styled.div<{ theme: Theme }>`
  display: flex;
  gap:24px;
  flex-direction: column;
  grid-column: 2 / span 1;

  .image-cluster {
    display: flex;
    flex-direction: column;
  }
`;

export const Caption = styled.a<{ theme: Theme; link?: boolean }>`
  ${getBodyStyle('graphikCond', { default: 'xsmall' })};
  color: ${({ link }) => getColor(link ? 'textLink' : 'textSecondary')};
  text-decoration: none;
  margin-top: 8px;
  text-align: center;
`;

export const Content = styled.div`
  grid-row: 1;
  grid-column: 1 / span 1;
    
  .read-more-button {
    margin-top: 8px;
  }  
`;

export const Title = styled(Heading)<{ theme: Theme }>`
  margin-bottom: 24px;

  &.card-title {
    margin: 0px 0px 8px;
  }

  a {
    display: inline-block;
  }

  &.has-subtitle {
    margin-bottom: 8px;
  }
`;

export const Subtitle = styled.span<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' })};
  color: ${getColor('textPrimary')};
  display: block;

  &.is-advertisement {
  ${getBodyStyle('graphikCond', { default: 'large' })};
  }

  &.has-description {
    margin-bottom: 24px;
  }
`;

export const CreditCardsSection = styled.div<{ theme: Theme }>`
  padding: 8px;
  background-color: ${getColor('shadeBrandPrimary100')};
  color: ${getColor('textPrimary')};
  ${getBodyStyle('graphikCond', { default: 'default' })};
  
  .credit-section-title {
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .credit-section-text {
    line-height: 24px;
    font-weight: 400;
  }
`;

export const Description = styled.section<{ $isExpanded: boolean; theme: Theme; type?: string }>`
  color: ${getColor('textPrimary')};
  ${getBodyStyle('graphikCond', { default: 'default' })};

  ${({ $isExpanded }) =>
    !$isExpanded &&
    css`
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    @media (${breakpoint.mdMin}) {
      -webkit-line-clamp: 2;
    }
  `}
`;

export const BottomSection = styled.section<{ theme: Theme; type?: string }>`
  grid-column: 1 / span 2;
  color: ${getColor('textPrimary')};
  ${getBodyStyle('graphikCond', { default: 'default' })};
  table {
    margin-top: 0;
  }
  & > p {
    margin: 0;
  }
`;

export const Name = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};
`;

export const RatesLine = styled.div<{ theme: Theme }>`
  display: flex;
  gap:8px;
  ${getBodyStyle('graphikCond', { default: 'default' })};
  margin-bottom: 10px;

  .circle {
    ${getBodyStyle('graphikCond', { default: 'xsmall' }, 'semiBold')};
    text-align: center;
    color: ${getColor('textWhite')};
    background: ${getColor('surfaceBrand')};
    border-radius: 50%;
    min-width: 24px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .rates-text {
    ${getBodyStyle('graphikCond', { default: 'default' })};
  }
`;

export const TableWrapper = styled.div<{ theme: Theme }>`
  ${getBodyStyle('graphikCond', { default: 'default' })};
`;

export const TableTitle = styled.h3<{ theme: Theme }>`
  color: ${getColor('textPrimary')};
`;

export const Table = styled.table<{ theme: Theme }>`
  width: 100%;
  border-collapse: collapse;

  tr:not(:last-child) {
    border-bottom: 1px solid ${getColor('borderSecondary')};
  }
`;

export const TableRow = styled.tr`
  padding: 10px 0;
`;

export const RowLabel = styled.td<{ theme: Theme }>`
  color: ${getColor('textSecondary')};
  padding: 8px 0;
  ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};
`;

export const RowValue = styled.td<{ theme: Theme }>`
  display: flex;
  gap: 8px;
  justify-content: right;
  align-items:center;
  color: ${getColor('textAccentPrimary')};
  text-align: right;
  ${getBodyStyle('graphikCond', { default: 'default' }, 'semiBold')};
`;

export const Button = styled(CustomLink)`
  &.button-desktop {
    width: 100%;
    margin-top: auto;
    // special case for product cards - the buttons will have smaller paddings
    padding: 16px;

    &.no-image{
      margin-top: 0;
    }
  }
`;

export const SectionContainer = styled.div`
  grid-column: span 2;
`;
